import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "components/loader";

// import { useGetInTouch } from "hooks/Forms/useGetInTouch";
import ThankYouPopup from "components/popup/thankYouPopup";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import ContactForm from "./ContctUs/contactForm";
import { useContact } from "hooks/Forms/useContact";

export default function GetInTouch({ backgroundCol }) {
  const [open, setOpen] = useState(false);

  const [token, setToken] = useState(false);
  const [phonenumber, setPhonenumber] = useState("");
  const [countrycode, setCountrycode] = useState("1");
  const captchaRef = useRef(null);
  const { status, mutateAsync } = useContact();

  const [captchaVisible, setCaptchaVisible] = useState(false);
  // const [checkBox, setCheckBox] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const location = useLocation();

  // const { status, mutateAsync } = useGetInTouch();

  const onSubmit = (data) => {
    const postData1 = {
      first_name: data.name,
      last_name: data.lastname,
      email: data.email,
      message: data.message,
      pagelink: location.href,
      consent: data.consent,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
      setOpen(true);
    });
  };

  return (
    <div className={`${backgroundCol ? backgroundCol : "bg-[#000c27]"}`}>
      <ThankYouPopup open={open} setOpen={setOpen} />
      <div className="mainContainer flex lg:flex-row flex-col text-textgray py-36 font-opensans xs:font-secondary 2xl:w-9/12 w-full justify-center md:gap-x-28 gap-x-0 mx-auto px-6 max-w-max">
        <div className="left text-gray sm:max-w-md lg:max-w-[38rem]">
          <div className="getInTouch text-center ">
            <span className="uppercase font-bold text-6xl">Get In </span>
            <span className="uppercase font-bold text-6xl py-4">Touch</span>
          </div>
          {/* <div className="countries w-full  flex lg:flex-row flex-col lg:gap-x-10 gap-x-0 pt-14"> */}
          <div className="countries w-full grid grid-cols-2 lg:grid-cols-3 gap-8 lg:pt-14">
            <div className="div lg:pt-0 pt-12">
              <span className="font-bold mb-5 text-orange">UNITED STATES</span>
              <p className="text-sm">Tomedes Ltd - USA 9450</p>
              <p className="text-sm">SW Gemini Dr #34540,</p>
              <p className="text-sm">Beaverton,</p>
              <p className="text-sm">OR 97008-7105</p>
              <p className="text-sm">Call : +1 985 239 0142</p>
            </div>
            <div className="div lg:pt-0 pt-12">
              <span className="font-bold mb-5 text-orange">MIDDLE EAST</span>
              <p className="text-sm">26 HaRokmim st.</p>
              <p className="text-sm">
                Azrieli Business Center, Building C, 7th floor,
              </p>
              <p className="text-sm">Holon 5885849, Israel</p>
              <p className="text-sm">Call : +972 (0)72 220 0700</p>
            </div>
            <div className="div lg:pt-0 pt-12 ">
              <span className="font-bold mb-5 text-orange">EUROPE</span>
              <p className="text-sm">7 Bell Yard,</p>
              <p className="text-sm">London, WC2A</p>
              <p className="text-sm">2 JR United Kingdom</p>
              <p className="text-sm">Call: +44 (0)16 1509 6140</p>
            </div>
          </div>
        </div>
        {/* <form
          className="right grid-rows-4 text-white lg:w-5/12 w-full relative text-sm font-light lg:pt-0 pt-16 xl:w-96"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label htmlFor="" className="flex flex-col">
            First Name *
            <input
              type="text"
              name="name"
              className="h-8 px-4 mt-4 text-black"
              placeholder="Your first name"
              {...register("name", {
                required: true,
              })}
            />
            {errors.name && (
              <span className="text-red text-xs pt-2">{errorMessage}</span>
            )}
          </label>
          <label htmlFor="" className="flex flex-col mt-6">
            Last Name *
            <input
              type="text"
              name="lastname"
              className="h-8 px-4 mt-4 text-black"
              placeholder="Your last name"
              {...register("lastname", {
                required: true,
              })}
            />
            {errors.lastname && (
              <span className="text-red text-xs pt-2">{errorMessage}</span>
            )}
          </label>
          <label htmlFor="" className="flex flex-col mt-6">
            Email *
            <input
              type="email"
              name="email"
              className="h-8 px-4 mt-4 text-black"
              placeholder="Your email address"
              {...register("email", {
                required: true,
                pattern: patternEmail,
              })}
            />
            {errors.email && (
              <span className="text-red text-xs pt-2">{errorMessageEmail}</span>
            )}
          </label>
          <label htmlFor="" className="flex flex-col mt-6">
            Tell Us More About Your Needs
            <textarea
              name="message"
              id=""
              cols="40"
              rows="3"
              className="rounded px-4  pt-2 mt-4 text-black"
              placeholder="One member of our team will contact you within a few minutes"
              {...register("message")}
            ></textarea>{" "}
          </label>
          <p className="text-sm text-white font-opensans pt-2">
            *I agree to Tomedes'&nbsp;
            <Link
              to="/privacy"
              className="text-orange hover:text-newOrange-100 underline transition-colors"
            >
              Privacy Policy
            </Link>
            &nbsp;by submitting this form.
          </p>
          <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          <button
            type="submit"
            className="bg-orange rounded-full px-10 py-2 text-white mt-6  left-0 lg:right-0 pointer-events-auto hover:bg-[#ff9a16] desk-submit"
            id="bottomform_submit"
          >
            {status === "loading" ? <Loader /> : "SUBMIT"}
          </button>
        </form> */}
        <ContactForm
          open={open}
          setOpen={setOpen}
          phonenumber={phonenumber}
          setPhonenumber={setPhonenumber}
          status={status}
          mutateAsync={mutateAsync}
          location={location}
          captchaVisible={captchaVisible}
          setCaptchaVisible={setCaptchaVisible}
          lebeltextColour="[#ffffff]"
        />
      </div>
    </div>
  );
}
